import * as React from 'react';
import styles from '../agtech.module.css';
import Booking from 'components/feedback';
import Tile from 'components/tile';
import GetConsultation from 'components/free-consultation';
import Blog from 'components/blog/widget';
import Layout from '../components/layout';
import { EveryPig, EveryPigLogistic } from 'components/carousel';
import loadable from '@loadable/component';
import Bullets from 'components/bullets';
import identificators from 'components/googleAnalyticsIdentificators';

import { meta } from '../../../metaData';
import FAQ from 'components/faq';
import { useFaqData } from 'hooks/useFaqData';

const Routes = require('../../routes.js').Routes;

const LIST = [
  {
    title: 'Digital Transformation',
    text: 'Get digitalized with state-of-the-art technology to stay out of the competition.',
  },
  {
    title: 'Farm Management Software',
    text: 'Come up with a custom farm management platform to make data-driven decisions.',
  },
  {
    title: 'Process Automation Software',
    text: 'Make the best out of automated steering for machinery.',
  },
  {
    title: 'Livestock Management Solutions',
    text: 'Keep track of animals’ health conditions and location to process and analyze real-time data.',
  },
  {
    title: 'Custom CRM',
    text: 'Develop a smart-based network of suppliers and customers to provide a decent customer experience.',
  },
  {
    title: 'Supply Chain and Food Security',
    text: 'Track the location of your goods and monitor the environmental conditions while in transit.',
  },
];

const TRANSFORM = [
  {
    name: 'agritech-startup',
    title: 'AgriTech Startup',
    text: 'Have a revolutionary idea? We are eager to create a project from scratch or perfect your existing MVP.',
    to: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  {
    name: 'agritech-b2b-company',
    title: 'AgriTech B2B Company',
    text: 'We gather a dedicated team to take care of every development step while saving your budget.',
    to: Routes.SERVICES_DEDICATED_TEAM,
  },
  {
    name: 'farm-owner',
    title: 'Farm Owners',
    text: 'We embrace the latest tech trends to reshape your legacy infrastructure with digitally-enabled solutions.',
    to: Routes.SERVICES_DIGITAL_TRANSFORMATION,
  },
];

interface Props {
  pathContext: {
    prePost: ReadonlyArray<any>;
  };
  location: Location;
}

const SwiperSlider = loadable(() => import('components/swiper-slider'));

function Agtech({ pathContext: { prePost }, location }: Props) {
  const faqData = useFaqData('allStrapiIndustry', 'Agritech');

  return (
    <Layout
      title="AgriTech Software Development"
      subtitle="We apply the best practices and innovative tech trends to help agritech-related companies and startups deliver a fascinating experience to their end clients."
      linkText="Contact us"
      metaData={{ main: meta.agritech, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.CONTACT_US_HEADER}
    >
      <section className="section">
        <div className="inner">
          <h2 className="title">Our expertise in AgriTech</h2>
          <p className="subtitle">We let you keep everything under control </p>
          <Bullets list={LIST} className={styles.bullets} />
        </div>
      </section>
      <SwiperSlider>
        <EveryPig />
        <EveryPigLogistic />
      </SwiperSlider>
      <section className="section">
        <div className="inner">
          <h2 className="title">Services we provide</h2>
          <p className="subtitle">Shifting your agricultural business to the new heights</p>
          <Tile list={TRANSFORM} className={styles.imageBullets} />
        </div>
      </section>
      <GetConsultation
        title="Are you interested in our AgriTech solutions?"
        text="Schedule a call with our experts to get a free consultation"
        linkText="Schedule a call"
        linkId={identificators.SCHEDULE_A_CALL_BANNER}
      />
      <section className="section">
        <div className="inner">
          <FAQ data={faqData} />
          <Blog posts={prePost} />
        </div>
      </section>
      <Booking />
    </Layout>
  );
}

export default Agtech;
